<script lang="ts" setup>
import { Employee } from './EmployeeSingle.vue'

defineProps<{
  person: Employee
  hideImage?: boolean
}>()

const controller = useModal()
</script>
<template>
  <article>
    <Image v-if="!hideImage" type="employee-teaser" :entity="person.image" :alt="person.name" />
    <h4 class="name">{{ person.name }}</h4>
    <p v-if="person.position" class="position text-small" v-html="person.position" />
    <div class="slot-wrapper">
      <slot />
    </div>
  </article>
</template>
<style lang="scss" scoped>
.dp-image {
  display: block;
  --image-size: 240px;
  @include breakpoint(tl) {
    --image-size: 280px;
  }

  width: 100%;
  max-width: var(--image-size);
  aspect-ratio: 1;

  object-fit: cover;

  margin-bottom: 32px;
}
.name {
  margin-bottom: 4px;
}
.position {
  margin-bottom: 24px;
}
.slot-wrapper {
  margin-top: 24px;
}
</style>
